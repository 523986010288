<template>
  <div class="">
    <director />
    <div class="cleafix"></div>
    <chairman v-if="showChairman()" />
  </div>
</template>

<script>
import Director from './components/Director'
import Chairman from './components/Chairman'
export default {
  name: 'app',
  components: {
    'director': Director,
    'chairman': Chairman
  },
  mounted () {
    this.$store.commit('setEoId', document.body.getAttribute('data-eo-id'))
    this.$store.commit('setChairmanPhoto', document.getElementById('aside-params').getAttribute('data-chairman'))
    this.$store.commit('setSovet', document.getElementById('aside-params').getAttribute('data-sovet'))
    this.$store.dispatch('getDataInfo')
    this.$store.dispatch('getChairmanInfo')
  },
  methods: {
    showChairman () {
      return window.location.hostname !== 'noumoskvich.mskobr.ru'
    }
  }
}
</script>

<style lang="less">
  .dir-box{
    float: left;
    width: 100%;
    margin: 0px -15px 40px -15px;
    .kris-dirtxt-marg{
      margin-left: 20px;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
    transition-duration: 0.1s;
  }
  .kris-dirtxt-marg{
    .title{
      font-size: 16px;
      margin: 0 0 10px;
    }
  }
  .link-more{
    margin: 10px 0;
    font-size: 13px !important;
    font-weight: 600;
    display: block;
  }
</style>
