<template>
  <div :ref="'photo_container_' + name" class="photo-director" v-if="photo !== false">
    <img :ref="'photo_' + name" :src="photo" @load="loadedPhoto"/>
  </div>
  <div v-else class="def-director">
    <img alt="" src="https://static.mskobr.ru/img/foto275x178.jpg">
  </div>
</template>

<script>
export default {
  name: 'Photo',
  props: ['name', 'photo'],
  methods: {
    loadedPhoto () {
      let width = this.$refs['photo_' + this.name].clientWidth
      let height = this.$refs['photo_' + this.name].clientHeight
      if (width > height) {
        this.$refs['photo_' + this.name].style.width = '105%'
      } else {
        this.$refs['photo_container_' + this.name].style.width = width + 'px'
        this.$refs['photo_container_' + this.name].style.height = height + 'px'
        this.$refs['photo_container_' + this.name].style.margin = '0 auto 20px'
      }
    }
  }
}
</script>

<style lang="less">
  .photo-director{
    img{
      margin-bottom: 20px;
    }
  }
  .def-director{
    img{
      width: 105%;
    }
  }
</style>
