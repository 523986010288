import { render, staticRenderFns } from "./Chairman.vue?vue&type=template&id=20d71508&scoped=true&"
import script from "./Chairman.vue?vue&type=script&lang=js&"
export * from "./Chairman.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20d71508",
  null
  
)

export default component.exports