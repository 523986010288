<template>

    <div class="modal fade" id="contacts" data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h2 class="modal-title" id="myModalLabel">Контакты руководителя</h2>
        </div>
        <div class="modal-body">
          <div class="">
            <h3 v-if="typeof(info.chief_position) !== 'undefined'">{{ info.chief_position }}</h3>
            <h3 v-else>Директор:</h3>
            <div class=""><p v-if="typeof (info.xa_director) !== 'undefined'">{{ info.xa_director }}</p></div>
            <div class="">
              <p v-if="typeof (info.director_email) !== 'undefined'">
                <a :href="'mailto:'+ info.director_email">{{ info.director_email }}</a>
              </p>
              <div class="clearfix"></div>
            </div>
            <div class="">
              <p v-if="typeof (info.tel_city) !== 'undefined'">
                <a v-if="info.tel_city" :href="'tel:+7 ' + info.tel_city ">+7 {{ info.tel_city }}</a>
              </p>
              <div class="clearfix"></div>
            </div>
            <div class="">
<!--              <p v-if="typeof (info.tel_mobil_public) !== 'undefined'">-->
<!--                <a v-if="info.tel_mobil_public" :href="'tel:+7 ' + info.tel_mobil_public">+7 {{ info.tel_mobil_public }}</a>-->
<!--              </p>-->
              <p v-if="typeof (info.user_telephon_city) !== 'undefined'">
                <a v-if="info.user_telephon_city" :href="'tel:+7 ' + info.user_telephon_city">+7 {{ info.user_telephon_city }}</a>
              </p>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="" v-if="params">
<!--            <div class="">-->
<!--              <p v-if="typeof (params.director_phone_extra) !== 'undefined'">-->
<!--                <span>Добавочный:</span> {{ params.director_phone_extra }}-->
<!--              </p>-->
<!--              <div class="clearfix"></div>-->
<!--            </div>-->
            <div>
              <div class="">
                <h3 v-if="typeof (params.secretary_post) !== 'undefined'">{{ params.secretary_post }}</h3>
                <h3 v-else>Секретарь</h3>
                <div class="clearfix"></div>
              </div>
              <div class="">
                <p v-if="typeof (params.secretary_name) !== 'undefined' ">{{ params.secretary_name }}</p>
                <div class="clearfix"></div>
              </div>
              <div class="">
                <p v-if="typeof (params.secretary_phone) !== 'undefined'">
                  <a :href="'tel:' + params.secretary_phone ">{{ params.secretary_phone }}</a>
                </p>
                <div class="clearfix"></div>
              </div>
              <div class="">
                <p v-if="typeof (params.secretary_phone_extra) !== 'undefined'">
                  <span>Добавочный:</span> {{ params.secretary_phone_extra }}
                </p>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
          <div v-if="typeof (info.email) !== 'undefined'">
            <h3>Адрес электронной почты:</h3>
            <p><a :href="'mailto:' + info.email ">{{ info.email }}</a></p>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Popup',
  computed: {
    ...mapState({
      'info': 'dataInfo',
      'params': 'dataParams'
    })
  }
}
</script>

<style scoped>

</style>
