<template>
<div class="dir-box">
  <transition name="fade">
    <photo
      v-if="info"
      name="director"
      :photo="info.photo !== null ? 'https://st.educom.ru' + info.photo.url : false"
    />
    <loading v-else></loading>
  </transition>
  <div class="kris-dirtxt">
    <div class="kris-dirtxt-marg">
      <a href="/director/">Обращение руководителя</a>
      <div class="h2">{{ typeof(info.chief_position) !== 'undefined' ? info.chief_position + ':':'Директор:' }}</div>
      <p>{{ info.xa_director }}</p>
      <a href="#" class="dotted" data-toggle="modal" data-target="#contacts"
         @click="$store.dispatch('getDataParams')">Контакты руководителя</a>
      <popup-info></popup-info>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import Photo from './Photo'
import Popup from './Popup'
import Loading from './Loading'

export default {
  name: 'DirectorPhoto',
  components: {
    'photo': Photo,
    'popup-info': Popup,
    'loading': Loading
  },
  computed: {
    ...mapState({
      info: 'dataInfo',
      photoDirector: 'photoDirector'
    })
  }
}
</script>

<style lang="less">
</style>
