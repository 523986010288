import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    eoId: false,
    ekis: 'https://st.educom.ru',
    info: '/soap/process.php?do=getFileDirector&eo_id=',
    // api: 'https://api-sites.mskobr.ru',
    api: 'https://info-sites.mskobr.ru',
    // params: '/params/get/site/params',
    params: '/api/params',
    dataParams: false,
    dataInfo: false,
    chairmanPhoto: false,
    chairmanInfo: false,
    sovet: false
  },
  mutations: {
    setEoId (state, value) {
      state.eoId = value
    },
    setDataInfo (state, value) {
      state.dataInfo = value
    },
    setDataParams (state, value) {
      state.dataParams = value
    },
    setChairmanPhoto (state, value) {
      state.chairmanPhoto = value
    },
    setChairmanInfo (state, value) {
      state.chairmanInfo = value
    },
    setSovet (state, value) {
      state.sovet = value
    }
  },
  actions: {
    getDataInfo () {
      let path = this.state.ekis + this.state.info + this.state.eoId
      Axios({
        url: path,
        method: 'get'
      }).then((res) => {
        if (res.status === 200) {
          this.commit('setDataInfo', res.data)
        }
      })
    },
    getChairmanInfo ({ state, commit }) {
      if (state.chairmanInfo === false) {
        let path = this.state.api + '/api/ekis/chairman.json?eoId=' + this.state.eoId
        return Axios({
          url: path,
          method: 'GET'
        }).then((res) => {
          if (res.data.response === 'success') {
            commit('setChairmanInfo', res.data.data)
          } else {
            commit('setChairmanInfo', [])
          }
        })
      }
    },
    getDataParams () {
      let path = this.state.api + this.state.params + '.json?eoId=' + this.state.eoId
      Axios({
        url: path,
        method: 'GET'
      }).then((res) => {
        if (res.status === 200 && res.data.response === 'success') {
          this.commit('setDataParams', res.data.result)
        }
      })
    }
  }
})
